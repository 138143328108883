<template>
    <div class="TeamTestList">
        <el-card class="box-card">
            <div>
                <div>
                    <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                    <div class="clearfix">
                        状态评估
                    </div>
                </div>
                <el-divider></el-divider>
                <!-- <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div>状态评估</div> -->
                <div slot="header" class="clearfix">
                    <el-form :inline="true" class="demo-form-inline" :model="deviceAssessFram" ref="deviceAssessFram">
                        <el-form-item label="仪器评级">
                            <el-select placeholder="请选择仪器评估级别" v-model="deviceAssessFram.deviceGrade">
                                <el-option v-for="(item, index) in assessList" :key="index" :label="item.name"
                                    :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="仪器状态">
                            <el-select placeholder="请选择仪器状态" v-model="deviceAssessFram.deviceStatus">
                                <el-option v-for="(item, index) in deviceStatusList" :key="index" :label="item.name"
                                    :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="评估日期">
                            <el-date-picker v-model="deviceAssessFram.evaluationDate" format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd" type="date" placeholder="选择评估日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                            <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                        </el-form-item>
                    </el-form>

                    <div class="clearfix">
                        <el-button class="btn" @click="handleAddAssess" icon="el-icon-circle-plus-outline">新增</el-button>
                    </div>
                </div>
            </div>


            <!-- 列表 -->
            <el-table :data="deviceAssessList" border style="width: 100%; font-size: 13px;" v-loading="loading"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="evaluationDate" label="评估日期" align="center">
                </el-table-column>
                <el-table-column prop="evaluator" label="评估人" align="center">
                </el-table-column>
                <el-table-column prop="deviceStatus_text" label="仪器状态" align="center">
                </el-table-column>
                <el-table-column prop="deviceGrade_text" label="仪器评级" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceAssessFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="deviceAssessFram.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { deviceStatusList } from '@/api/deviceKeepLog.js'
import { assessList, serchDeviceAssess } from '@/api/deviceAssess.js'
export default {
    props: {
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            assessList: assessList,
            deviceStatusList: deviceStatusList,
            deviceAssessList: [],
            deviceAssessFram: {
                did: this.did,
                deviceStatus: null,
                deviceGrade: null,
                evaluationDate: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false,
        }
    },
    mounted() {
        this.loadDeviceAnnex()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceAssessFram.pageSize = val;
            this.deviceAssessFram.pageNum = 1;
            this.loadDeviceAnnex();
        },
        handleCurrentChange(val) {
            this.deviceAssessFram.pageNum = val;
            this.loadDeviceAnnex();
        },
        loadDeviceAnnex() {
            this.loading = true
            serchDeviceAssess(this.deviceAssessFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.deviceAssessList = res.t.list
                        let count = 1;
                        this.deviceAssessList.forEach(item => {
                            item.seq = count++
                            const index = this.assessList.findIndex(item1 => item1.code === item.deviceGrade)
                            if (index != -1) {
                                item.deviceGrade_text = this.assessList[index].name
                            }

                            const index1 = this.deviceStatusList.findIndex(item1 => item1.code === item.deviceStatus)
                            if (index1 != -1) {
                                item.deviceStatus_text = this.deviceStatusList[index1].name
                            }
                        })
                    }

                }, 200)
            })
        },

        handleAddAssess() {
            this.$router.push({
                name: 'whManage-deviceAssess-add',
                params: {
                    did: this.did
                }
            })
        },
        handleEdit({ id }) {
            this.$router.push({
                name: 'whManage-deviceAssess-edit',
                params: {
                    did: this.did,
                    id: id
                }
            })
        },
        handleDetail({ id }) {
            console.log(id)
            this.$router.push({
                name: 'whManage-deviceAssess-detail',
                params: {
                    did: this.did,
                    id: id
                }
            })
        },

        //搜索
        handleSearch() {
            this.loadDeviceAnnex(this.deviceAssessFram);
        },
        //重置
        handleReset() {
            this.deviceAssessFram = {
                did: this.did,
                deviceStatus: null,
                deviceGrade: null,
                evaluationDate: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.loadDeviceAnnex(this.deviceAssessFram);
        }
    }
}
</script>



<style lang="scss" scoped>
.TeamTestList {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
        }

        .cha:hover {
            cursor: pointer;
            color: #006DC9;
        }

        .clearfix {
            text-align: left;
            margin-bottom: 20px;
        }
    }

    // .el-pagination,
    // .clearfix {
    //     text-align: left;
    // }

    .delColor {
        color: red;

    }

}
</style>